/* eslint-disable react/no-unstable-nested-components */

import { HorizontalStackBarChart, VerticalStackBarChart } from 'app/components/charts';
import {
  Block, BlockTitle, CardChart, FieldDisplayer, LoadingSpinner,
} from 'app/components/common';
import {
  SimulationProductError,
  StackedBarChartConfig,
  WithCurrencySwitch,
  WithPolicyProjectionCTA,
} from 'app/utils/types';
import React from 'react';
import { MEDIA_QUERIES, useMediaQuery } from 'app/utils/hooks/useMediaQuery';
import { getWording } from 'app/constants/wording/wording';
import { getIcon } from 'app/utils/staticStorage';
import { createUrlWithQueryParams, getQueryParams } from 'app/utils/queryParams';
import { AxiosError } from 'axios';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { CurrencySwitch } from 'app/components/common/CurrencySwitch';
import { CTACard } from 'app/components/common/CTACard';
import { POLICY_PROJECTION_URL } from 'app/utils/constants';
import { DisclaimersList } from 'app/components/common/DisclaimersList';
import { } from 'app/components/common/InformativeDeclarationDisclaimer';
import { DisclaimerByRegimeAndPlan } from 'app/components/common/DisclaimerByRegimeAndPlan';
import { useApvLife } from './useApvLife';
import { ApvLifePayload, ApvLifeResponse } from './apvLifeService';
import { ApvLifeSavingsUnblundingCards } from './ApvLifeSavingsUnbundlingCards';
import { SimulationError } from '../SimulationError';

interface ApvLifeContentProps extends WithCurrencySwitch, WithPolicyProjectionCTA {
  simulationResult: ApvLifeResponse,
  chartData: StackedBarChartConfig,
  containerClassname?: string
}

function ApvLifeContent({
  simulationResult,
  chartData,
  containerClassname,
  showCurrencySwitch,
  showPolicyProjectionCTA,
}: ApvLifeContentProps) {
  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);
  const {
    monthly_pension_without_savings,
    monthly_pension_with_savings,
    monthly_pension_afp_with_apv_transfer,
    monthly_pension_with_afp,
    monthly_insurance_cost,
    total_fiscal_contribution_without_interest,
    total_personal_savings_without_interest,
    total_savings_interest,
    monthly_savings,
    average_monthly_cost,
  } = simulationResult;

  const {
    apv_regime,
    gender,
    compensation_plan,
    savings_option,
    initial_contribution,
    initial_contribution_kind,
    agreed_deposits,
    agreed_deposits_kind,
    apv_transfer_amount,
    apv_transfer_origin,
    expected_retirement,
    death_insured_capital,
  } = getQueryParams<ApvLifePayload>();

  const { wording } = getWording();
  const rawQueryParams = getQueryParams();

  const { currencyPrefix } = useCurrencyContext();

  const totalSavings = total_fiscal_contribution_without_interest
    + total_personal_savings_without_interest
    + total_savings_interest;

  function getRetirementYears() {
    if (expected_retirement) return expected_retirement;

    if (gender === 'MALE') return 65;

    return 60;
  }
  return (
    <section className={containerClassname ?? 'simulation-renderer'}>
      {showCurrencySwitch && <CurrencySwitch />}

      <ApvLifeSavingsUnblundingCards
        apv_regime={apv_regime}
        apv_transfer_amount={apv_transfer_amount}
        apv_transfer_origin={apv_transfer_origin}
        initial_contribution={initial_contribution}
        initial_contribution_kind={initial_contribution_kind}
        agreed_deposits={agreed_deposits}
        agreed_deposits_kind={agreed_deposits_kind}
        monthly_pension_with_savings={monthly_pension_with_savings}
        monthly_pension_without_savings={monthly_pension_without_savings}
        monthly_pension_afp_with_apv_transfer={monthly_pension_afp_with_apv_transfer}
        monthly_pension_with_afp={monthly_pension_with_afp}
        monthly_savings={monthly_savings}
        savings_option={savings_option}
      />

      <Block className="mb-3">
        <BlockTitle
          extraClassName="fs-5 text-center mb-2 lh-base"
          primaryText={{ content: `Así se distribuirán ${wording.yours} ahorros a lo largo del tiempo hasta cuando ${wording.wouldBe} ${wording.retirement} a los `, color: 'text-primary' }}
          secondaryText={{ content: `${getRetirementYears()} años:` }}
        />

        <CardChart
          renderText={() => (
            <div className="text-center">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span className="text-secondary">total de ahorros </span>
              <span>{`en ${wording.your} Seguro con APV podría ser de:`}</span>
            </div>
          )}
          renderFieldDisplayer={() => (
            <div className="mb-3 d-flex justify-content-center">
              <FieldDisplayer
                overwrittenClassName="fw-bold px-2_5 py-2 d-inline-block rounded-4 fs-1 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{
                  content: r(totalSavings),
                  color: 'text-dark',
                }}
              />
            </div>
          )}
          renderChart={() => (isMobile
            ? <VerticalStackBarChart chartData={chartData} />
            : <HorizontalStackBarChart chartData={chartData} labelCol={3} />)}
        />
      </Block>

      {showPolicyProjectionCTA && (
        <Block className="mb-3">
          <CTACard
            icon={{
              src: getIcon('circle-shield-star.svg'),
              alt: '',
            }}
            renderContent={() => (
              <div className="text-center fs-small">
                <p className="mb-2">
                  <span>El </span>
                  <span className="text-secondary">costo promedio mensual </span>
                  <span>{`de ${wording.your} Seguro con APV sería de `}</span>
                  <span className="fw-bold">{`${r(average_monthly_cost, { withPrefix: true })} `}</span>
                  <span>y la </span>
                  <span className="text-secondary">prima básica </span>
                  <span>sería de </span>
                  <span className="fw-bold">{`${r(monthly_insurance_cost, { withPrefix: true })}.`}</span>
                </p>

                <p className="m-0">
                  <span>Revisa como irán variando a través de los años.</span>
                </p>
              </div>
            )}
            renderCTA={() => (
              <a
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-primary"
                href={createUrlWithQueryParams(
                  POLICY_PROJECTION_URL, rawQueryParams as Record<string, string>
                )}
              >
                Ver proyección
              </a>
            )}
          />
        </Block>
      )}

      <Block className="px-4 mb-3">
        <DisclaimersList
          items={[
            'La presente simulación es una estimación meramente informativa, basada en datos generales y no constituye una oferta comercial.',
          ]}
        />
      </Block>

      <Block className="mb-3">
        <DisclaimerByRegimeAndPlan
          regime={apv_regime}
          plan={compensation_plan}
          insuredCapital={death_insured_capital}
        />
      </Block>
    </section>
  );
}

function EmptyContent() {
  const { wording } = getWording();

  return (
    <section className="simulation-renderer h-100 d-flex align-items-center justify-content-center">
      <figure className="d-flex flex-column  align-items-center justify-content-center mb-0">
        <img src={getIcon('rocket.svg')} alt="" className="mb-2_5" />

        <figcaption className="text-primary text-wrap text-center h6 fw-bold lh-base mb-0" style={{ width: 200 }}>
          {`Aquí verás cómo mejora ${wording.your} pensión`}
        </figcaption>
      </figure>
    </section>
  );
}

interface ApvLifeProps extends WithCurrencySwitch, WithPolicyProjectionCTA {
  containerClassname?: string
}

export function ApvLife({
  containerClassname,
  showCurrencySwitch,
  showPolicyProjectionCTA,
}: ApvLifeProps) {
  const {
    simulationResult,
    chartData,
    isError,
    error,
    isLoading,
    isEmpty,
  } = useApvLife();

  if (isEmpty) {
    return <EmptyContent />;
  }

  if (simulationResult && chartData) {
    return (
      <ApvLifeContent
        showPolicyProjectionCTA={showPolicyProjectionCTA}
        showCurrencySwitch={showCurrencySwitch}
        simulationResult={simulationResult}
        chartData={chartData}
        containerClassname={containerClassname}
      />
    );
  }

  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;
  if (isError) return <SimulationError error={error as AxiosError<SimulationProductError>} />;

  return null;
}
